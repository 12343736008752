export type Atoll = { shortKey: string; commonName: string; distanceKm: number }

export const ATOLLS_MAP: Record<string, Atoll> = {
	ha: {
		shortKey: 'Ha',
		commonName: 'North Thiladhunmathi',
		distanceKm: 300,
	},
	hdh: {
		shortKey: 'HDh',
		commonName: 'South Thiladhunmathi',
		distanceKm: 273,
	},
	sh: {
		shortKey: 'Sh',
		commonName: 'North Miladhunmadulu',
		distanceKm: 246,
	},
	n: {
		shortKey: 'N',
		commonName: 'South Miladhunmadulu',
		distanceKm: 164,
	},
	r: {
		shortKey: 'R',
		commonName: 'North Maalhosmadulu',
		distanceKm: 172,
	},
	b: {
		shortKey: 'B',
		commonName: 'South Maalhosmadulu',
		distanceKm: 113,
	},
	lh: {
		shortKey: 'Lh',
		commonName: 'Faadhippolhu',
		distanceKm: 140,
	},
	k: {
		shortKey: 'K',
		commonName: "Male' Atoll",
		distanceKm: 60,
	},
	aa: {
		shortKey: 'Aa',
		commonName: 'North Ari Atoll',
		distanceKm: 74,
	},
	adh: {
		shortKey: 'ADh',
		commonName: 'South Ari Atoll',
		distanceKm: 112,
	},
	v: {
		shortKey: 'V',
		commonName: 'Felidhu Atoll',
		distanceKm: 59,
	},
	m: {
		shortKey: 'M',
		commonName: 'Mulaku Atoll',
		distanceKm: 143,
	},
	f: {
		shortKey: 'F',
		commonName: 'North Nilandhu',
		distanceKm: 146,
	},
	dh: {
		shortKey: 'Dh',
		commonName: 'South Nilandhu',
		distanceKm: 178,
	},
	th: {
		shortKey: 'Th',
		commonName: 'Kolhumadulu',
		distanceKm: 215,
	},
	l: {
		shortKey: 'L',
		commonName: 'Hadhdhunmathi',
		distanceKm: 265,
	},
	ga: {
		shortKey: 'Ga',
		commonName: 'North Huvadhu Atoll',
		distanceKm: 398,
	},
	gdh: {
		shortKey: 'GDh',
		commonName: 'South Huvadhu Atoll',
		distanceKm: 430,
	},
	gn: {
		shortKey: 'Gn',
		commonName: 'Fuvahmulah',
		distanceKm: 498,
	},
	s: {
		shortKey: 'S',
		commonName: 'Addu Atoll',
		distanceKm: 546,
	},
}

type AtollKey = Atoll['shortKey']
const ATOLL_SHORT_KEYS: AtollKey[] = Object.keys(ATOLLS_MAP)
	.map((key) => ATOLLS_MAP[key]?.shortKey)
	.filter(Boolean) as AtollKey[]

// z.enum expects a non-empty array so to work around that
// we pull the first value out explicitly
export const ATOLL_SHORT_KEYS_FOR_ENUM: [AtollKey, ...AtollKey[]] = [
	ATOLL_SHORT_KEYS[0]?.toLowerCase() as AtollKey,
	// And then merge in the remaining values from the array
	...ATOLL_SHORT_KEYS.slice(1).map((key) => key.toLowerCase() as AtollKey),
]
